body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e0e0e0;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.swiper-pagination-bullet {
  background-color: rgb(188, 189, 189) !important;
}

.swiper-button-next {
  background-image: url(./img/R.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  filter: grayscale(100%);
  transform: rotateY(180deg);
}
.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  background-image: url(./img/R.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  filter: grayscale(100%);
}
.swiper-button-prev::after {
  display: none;
}
.ulcProgramConent .logo {
  background-color: #dcdcdc;
}

.navCssCh,
.subCat {
  display: none;
}
.navCss:hover .navCssCh {
  display: block;
}
.categoryNav:hover .subCat {
  display: block;
}
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  font-size: 20px !important;
}
.ulcProgramConent .logo {
  background-color: #dcdcdc;
}
/* .forBgImg{
  position: relative;
  z-index: 1;
} */
.forBgImg {
  background-image: url("./img//temple.png");
  background-repeat: no-repeat;
  object-fit: cover;
  height: 100%;
  width: 100%;
  background-position: bottom;
}
input::-webkit-inner-spin-button,
input ::-webkit-outer-spin-button {
  display: none;
}
.Toastify__toast-container{
  z-index: 100000000000000000000 !important;
}
.Toastify div{
  z-index: 100000000000000000000 !important;
}